/** @format */

import * as Mobx from "mobx";
import * as Models from "src/models";
import * as Services from "src/services";

export declare namespace Store {
  export type View = "TABULAR" | "CARD";

  export interface IStore {
    isLoading: boolean;
    orders: Models.Order.Main[];
    view: Store.View;
    orderService: Services.Order;
    licenseService: Services.License;
    articleService: Services.Article;
    assignLicense: Models.License[];
  }

  export type Options = Pick<
    IStore,
    "view" | "orderService" | "licenseService" | "articleService"
  >;

  export namespace SetView {
    export interface Props extends Pick<Store.IStore, "view"> {}
  }

  export namespace Read {
    export interface Props extends Services.Order.GetAllData.Props {}
    export interface OrderRequestProps extends Services.Order.GetData.Props {}
    export interface LicenseRequestProps
      extends Services.License.GetAllData.Props {}
  }
}

// eslint-disable-next-line no-redeclare
export class Store implements Store.IStore {
  @Mobx.observable public view: Store.View = "TABULAR";
  @Mobx.observable public orders: Models.Order.Main[] = [];
  @Mobx.observable public orderDetail: Models.Order.Main[] = [];
  @Mobx.observable public assignLicense: Models.License[] = [];
  @Mobx.observable public assignLicenseSingle: Models.License[] = [];
  @Mobx.observable public isLoading: boolean = false;
  public orderService: Services.Order;
  public licenseService: Services.License;
  public articleService: Services.Article;

  constructor({
    view,
    orderService,
    licenseService,
    articleService,
  }: Store.Options) {
    this.view = view;
    this.orderService = orderService;
    this.licenseService = licenseService;
    this.articleService = articleService;
  }

  @Mobx.action setView({ view }: Store.SetView.Props) {
    this.view = view;
  }

  @Mobx.action public async clearOrder() {
    try {
      this.orderDetail = [];
      this.orders = [];
      this.assignLicense = [];
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action public async read(props: Store.Read.Props) {
    try {
      this.orders = [];
      this.isLoading = true;
      const { result } = await this.orderService.getAllData(props);
      this.orders = result;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action
  public async getOrderAndLicense(
    orderRequest: Services.Order.GetData.Body,
    licenseRequest: Store.Read.LicenseRequestProps
  ) {
    try {
      this.isLoading = true;
      const { result } = await this.orderService.getData({
        body: orderRequest,
      });
      this.orders = result;

      const licenseResponse =
        await this.licenseService.getAllCustomerInviteData(licenseRequest);

      licenseResponse.result.map((k) => k);

      var licenseCodeUniqueList = licenseResponse.result.filter(
        (value, index, List) => {
          var deviceList = List.filter(
            (k) => k.licenseCode === value.licenseCode && k.device !== null
          ).map((m) => m.device);

          value.deviceList = deviceList;

          var licenseCodeList = List.filter(
            (k) => k.licenseCode === value.licenseCode
          ).map((m) => m.status);
          var maxStatus = Math.max(...licenseCodeList);

          value.status = maxStatus;

          return (
            List.findIndex((v) => v.licenseCode === value.licenseCode) === index
          );
        }
      );

      const articleResult = await this.articleService.getAllData({
        status: 1,
      });
      var orderObject: Models.Order.Main[];

      if (result.find((k) => k.status === 3)) {
        orderObject = result.filter((k) => k.status === 3);
        orderObject[0].overAllStatus = 3;
      }

      //overAllStatus = 2 -- > Order paretially returned
      else if (result.find((k) => k.status === 4)) {
        orderObject = result.filter((k) => k.status === 4);
        orderObject[0].overAllStatus = 4;

        //
      } else if (result.find((k) => k.status === 5)) {
        orderObject = result.filter((k) => k.status === 2);
        orderObject[0].overAllStatus = 5;

        //
      }
      //overAllStatus = 1 -- > Order paretially returned Order with no return
      else {
        orderObject = result.filter((k) => k.status === 1);
        orderObject[0].overAllStatus = 1;
      }

      var orderDetailIDList = orderObject[0].orderDetails.map(
        (m) => m.orderDetailID
      );

      var licenseList = licenseCodeUniqueList.filter((k) => {
        return orderDetailIDList?.includes(k.orderDetailID);
      });

      var licemsSetName = licenseList.map((k) => {
        return {
          ...k,
          setName:
            articleResult.result
              .filter((m) => m.setID === k.setID)
              .map((n) => n.setName)[0] ?? "",
          setCode:
            articleResult.result
              .filter((m) => m.setID === k.setID)
              .map((n) => n.hundCode)[0] ?? "",
        };
      });
      this.assignLicense = licemsSetName;
      this.assignLicenseSingle = licemsSetName;
      orderObject[0].license = licemsSetName;

      this.orderDetail = orderObject;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Mobx.action checkAssignAll() {
    if (
      this.orderDetail.length === 0 &&
      this.orderDetail[0].license.length === 0
    ) {
      return false;
    } else {
      var validForAssignAll =
        this.orderDetail[0].license.map((k) => k.assignedCustomerID).length > 0
          ? this.orderDetail[0].license
              .map((k) => k.assignedCustomerID)
              .every((k) => k === 0) &&
            this.orderDetail[0].license
              .map((k) => k.assignedTo)
              .every((k) => k === "0") &&
            !(
              this.orderDetail[0].license
                .map((k) => k.orderStatus)
                .some((k: any) => k === 3) ||
              this.orderDetail[0].license
                .map((k) => k.orderStatus)
                .some((k: any) => k === 4) ||
              this.orderDetail[0].license
                .map((k) => k.orderStatus)
                .some((k: any) => k === 5)
            )
          : false;

      return validForAssignAll;
    }
  }

  @Mobx.action checkUnAssignAll() {
    if (
      this.orderDetail.length === 0 &&
      this.orderDetail[0].license.length === 0
    ) {
      return false;
    } else {
      var validForAssignAll = this.orderDetail[0].license
        .map((k) => k.assignedTo)
        .every((k) => k !== "0")
        ? true
        : false;
      console.log("Un-Assign all" + validForAssignAll);
      return validForAssignAll;
    }
  }

  @Mobx.action setAssignLicense(licenseCode: any) {
    this.assignLicenseSingle = this.assignLicenseSingle.filter(
      (k) => k.licenseCode === licenseCode
    );
  }

  @Mobx.action getAssignLicense() {
    if (this.assignLicenseSingle.length === 0) {
      return [];
    } else {
      return this.assignLicenseSingle;
    }
  }

  @Mobx.action getAllAssignLicense() {
    if (this.assignLicense.length === 0) {
      return [];
    } else {
      return this.assignLicense;
    }
  }

  @Mobx.action getDistinctLicenseAndSumQuantityBySetID() {
    if (this.assignLicense.length === 0) {
      return [];
    } else {
      Object.values(
        this.assignLicense.reduce((hash: any, item) => {
          if (!hash[item.setID]) {
            hash[item.setID] = {
              name: item.setName,
              model: item.setCode,
              quantities: 0,
            };
          }
          hash[item.setID].quantities += item.quantities;
          return hash;
        }, {})
      );
      //
    }
  }

  @Mobx.computed public get isInitialLoading(): boolean {
    if (this.orders.length !== 0) {
      return false;
    }

    if (!this.isLoading) {
      return false;
    }

    return true;
  }
}
