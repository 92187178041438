/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Formik from "formik";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as Router from "react-router-dom";
import * as App from "src/app";
import * as Components from "src/app/components";
import * as Article from "src/app/pages/article";
import * as Generate from "src/app/pages/easy-touch-device/generate";
import * as Configs from "src/configs";
import * as Constants from "src/constants";
import * as Models from "src/models";
import * as Services from "src/services";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import * as Notistack from "notistack";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const Main = Mobx.observer(function () {
  const [data, setData] = React.useState<Models.Set.Article[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [dialogOpenLicenseAgreement, setDialogOpenLicenseAgreement] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [gmac, setGenMac] = React.useState<boolean>(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const tenantStore = React.useContext(App.Contexts.tenantStore);
  const generateLicenseStore = React.useContext(
    App.Contexts.license.generateStore
  );
  const useStyles = Mui.makeStyles({
    dialogTitle: {
      textAlign: 'center',
    },
      companyName: {
        color: "rgb(8,51,98)",
        fontWeight: 700,
        textAlign: 'center',
      },
      sectionTitle: {
        fontWeight: 600,
      },
      contactInfo: {
        fontWeight: 500,
      },
      footerText: {
        fontStyle: 'italic',
        fontWeight: 400,
        marginTop: '16px',
      },
  });
  const licenseStore = React.useContext(App.Contexts.license.store);
  const functionStore = React.useContext(App.Contexts.functionStore);
  const theme = Mui.useTheme();
  const { t } = ReactI18next.useTranslation();
  const navigate = Router.useNavigate();
  const [searchParams] = Router.useSearchParams();
  const userRole = userStore.user?.RoleName ?? "";
  const { enqueueSnackbar } = Notistack.useSnackbar();
  const articleService = new Services.Article({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });

  const licenseService = new Services.License({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  const dealerCustomerService = new Services.DealerCustomerService({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  

  React.useEffect(() => {
    tenantStore.setReturnUrl({ returnUrl: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (function () {
      const tenantId = searchParams.get("tenantId");
      
      if (!tenantId) {
        return;
      }

   

      tenantStore.read().then(() => {
        const tenant = tenantStore.tenants.find(
          (tenant) => tenant.id === parseInt(tenantId, 10)
        );

        generateLicenseStore.setSelectedTenant({ selectedTenant: tenant });
      });
    })();
    return () => {
      if (generateLicenseStore.created) {
        return;
      }

      generateLicenseStore.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const userId = userStore.user?.uid;
    const ETUID= userStore.user?.ETUID
    

    if (userId === undefined || userRole === undefined) {
      return;
    }

    generateLicenseStore.reset();
  
    articleService
      .getAllData({ status: 1,isManualActivation: true ,ETUID:ETUID,roleName:userRole})
      .then((result) => {
        const industryID = localStorage.getItem("industryID");
        console.log(result.result.toString());

        const industryFilter = result?.result?.filter(k => k.industrySegmentsId.toString() === industryID)

        setData(industryFilter);
        console.log(industryFilter.toString());
        const basicSet = industryFilter.filter(k=>k.hundCode === "SET-01");

        generateLicenseStore.setArticles({ articles: industryFilter });


        generateLicenseStore.setSelectedArticle({ article:basicSet[0] });
        const availableArticlesMap = new Map();
        industryFilter.forEach((article) => {
          availableArticlesMap.set(article.setID, article);
        });

        const sets = searchParams.getAll("set");

        sets.forEach((set) => {
          const article = availableArticlesMap.get(parseInt(set, 10));

          if (!article) {
            return;
          }

          if (
            Configs.Workaround.isCloudArticle({ code: article.hundCode }) ||
            Configs.Workaround.isCloudArticle({ code: article.setCode })
          ) {
            generateLicenseStore.setCloudArticle({ cloudArticle: article });
          }
        });

        if (sets.length) {
          generateLicenseStore.setSelectedArticles({
            selectedArticles: sets.reduce((acc, set) => {
              const article = availableArticlesMap.get(parseInt(set, 10));
              if (article) {
                generateLicenseStore.setIsBasicSetSelected({
                  article,
                  isArticleSelected: true,
                });
              }
              return { ...acc, [parseInt(set, 10)]: true };
            }, {}),
          });
        }
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateLicenseStore.industrySegmentId]);

  React.useEffect(() => {
    functionStore.read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    tenantStore.read();
  }, [generateLicenseStore.tenantSelectionDialogOpen, tenantStore]);
  React.useEffect(() => {
   const maccGenerate = searchParams.get("gnot") 
   
   setGenMac(maccGenerate === "true" ? true :false );
  }, [generateLicenseStore.tenantSelectionDialogOpen, searchParams, tenantStore]);


  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };

  async function handleCreate(
    values: Generate.Form.Values,
    { setSubmitting }: Formik.FormikHelpers<Generate.Form.Values>
  ) {
    try {
      setLoading(true);
      const sets: number[] = Object.keys(
        generateLicenseStore.selectedArticles
      ).reduce((acc: number[], key) => {
        if (generateLicenseStore.selectedArticles[key]) {
          return [...acc, parseInt(key, 10)];
        }
        return acc;
      }, []);
      if (sets.length === 0) {
        return;
      }

    

      const userId = userStore.user?.uid;
      if (userId === undefined) {
        throw new Error(t("uid is undefined"));
      }
      if (values.eT_version == null) {
        values.eT_version = 2;
      }

      if (userStore.user?.RoleName === "admin") {
        const { status, result } = await licenseService.Create({
          ...values,
          tenantId: generateLicenseStore.selectedTenant?.id,
          sets,
          userId,
        });

        if (status === 100 && (result as unknown as string) === "Error") {
          throw new Error(result as unknown as string);
        }

        generateLicenseStore.setCreated({
          created: {
            licenseKey: result.licenseKey,
            sets: data?.filter((article) => sets.includes(article.setID)),
          },
        });
      navigate("success")

      } else {
        console.log("else starts")
        const uid = userStore.user?.ETUID ?? "0";

        var manualLicenseAvailable =
          await licenseStore.getManualLicenseAvailableList({
            body: { status: null, userID: uid },
            userRole: "dealer",
            uid,
            isGetAll: true,
          });
          
        const manual = manualLicenseAvailable?.filter(
          ({ setID }) => sets.includes(setID)
        );
        var license: any = manual?.map((m) => {
          return {
            licenseDetailID: m.licenseDetailID,
            setID: m.setID,
          };
        });
        
        const { status, result } =
          await dealerCustomerService.CreateManualLicense({
            ETUID: userStore.user?.ETUID ?? 0,
            DeviceName: values.deviceName,
            ActivationAck: "1",
            Createdby: 0,
            LicenseList: license,
            LicenseKey: "",
            Remarks: values.remarks,
            ActivationKey: "fhvhjghfhgjbkjhgfgh",
            CustomerName: values.customerName ?? "",
            eT_version: values.eT_version,
            Location: values.location ?? "",
            MacId: values.macId,
          });
          console.log("create exceuted")
        if (status === 100 && (result as unknown as string) === "Error") {
          throw new Error(result as unknown as string);
        }

        generateLicenseStore.setCreated({
          created: {
            licenseKey: result.licenseKey,
            sets: data?.filter((article) => sets.includes(article.setID)),
          },
        });

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }
  const easyTouchDeviceStore = React.useContext(
    App.Contexts.easyTouchDeviceStore
  );
const generateFlag = (props:boolean)=>{


}
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedArticles = data
        ?.filter((article) => {
          if (!search) {
            return true;
          }
          return (
            (article.setName &&
              article.setName.toLowerCase().includes(search.toLowerCase())) ||
            (article.setCode &&
              article.setCode.toLowerCase().includes(search.toLowerCase())) ||
            (article.hundCode &&
              article.hundCode.toLowerCase().includes(search.toLowerCase()))
          );
        })
        .map((article) => {
          if (
            Configs.Workaround.isCloudArticle({ code: article.hundCode }) ||
            Configs.Workaround.isCloudArticle({ code: article.setCode })
          ) {
            generateLicenseStore.setCloudArticle({ cloudArticle: article });

            if (!generateLicenseStore.selectedTenant) {
              generateLicenseStore.setTenantSelectionDialogOpen({
                tenantSelectionDialogOpen: true,
              });
            }
          }

          return article.setID;
        })
        .reduce((acc, id) => {
          return { ...acc, [id]: true };
        }, {});
      generateLicenseStore.setSelectedArticles({
        selectedArticles: search
          ? { ...generateLicenseStore.selectedArticles, ...newSelectedArticles }
          : newSelectedArticles,
      });
      setSearch("");
      return;
    }

    const newSelectedArticles = data
      ?.filter((article) => {
        if (!search) {
          return true;
        }
        return (
          (article.setName &&
            article.setName.toLowerCase().includes(search.toLowerCase())) ||
          (article.setCode &&
            article.setCode.toLowerCase().includes(search.toLowerCase())) ||
          (article.hundCode &&
            article.hundCode.toLowerCase().includes(search.toLowerCase()))
        );
      })
      .map((article) => {
        if (
          Configs.Workaround.isCloudArticle({ code: article.hundCode }) ||
          Configs.Workaround.isCloudArticle({ code: article.setCode })
        ) {
          generateLicenseStore.setCloudArticle({ cloudArticle: undefined });
          generateLicenseStore.setSelectedTenant({ selectedTenant: undefined });
        }

        return article.setID;
      })
      .reduce((acc, id) => {
        return { ...acc, [id]: false };
      }, {});
    generateLicenseStore.setSelectedArticles({
      selectedArticles: search
        ? { ...generateLicenseStore.selectedArticles, ...newSelectedArticles }
        : {},
    });
    setSearch("");
  };
  const successAcknowledgement = () => {
    enqueueSnackbar(t("License(s) deactivated successfully."), {
      variant: "success",
      preventDuplicate: false,
    });

    easyTouchDeviceStore.read({
      userRole: "customer",
      status: 1,
      uid: userStore.user?.ETUID ?? 0,
    });
    navigate("/easy-touch-devices")
  };
  const classes = useStyles();
  return (
    
    <>
     
      <Dialog
        open={dialogOpen}
        keepMounted
        disableBackdropClick
        onClose={() => {
          setDialogOpen(false);
        }}
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
        <Mui.Grid
          style={{
           paddingLeft:"18px",
           paddingRight:"18px",
           marginBottom:"20px"
          }}
        >
         
      
       <Mui.Typography variant = "h6" style={{  opacity:"0.9" }}>
        <ReactI18next.Trans i18nKey="Installation key generated successfully.">
        Installation key generated successfully.
        </ReactI18next.Trans>
        </Mui.Typography>
          
          <Mui.Typography variant = "body1" style={{  opacity:"0.8", fontSize:"14px"}}>
          <ReactI18next.Trans i18nKey="Success!">
          The installation key generated can be copied for activating the easy touch manually.
          </ReactI18next.Trans>
          </Mui.Typography>
          </Mui.Grid>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
      <Mui.Grid style={{backgroundColor:"#e6ecff",padding:"15px"}}>
          <Mui.Typography style={{wordWrap:"break-word", fontSize:"13px"}}>{loading ?    <Mui.Box
               padding={2}
               display="flex"
               justifyContent="center"
               alignItems="center"
               width={"100%"}
               minHeight={"100%"}
               maxHeight={"100%"}
               height={100}
               flexDirection="column"
             >
               <Mui.CircularProgress />
             </Mui.Box> : generateLicenseStore.created?.licenseKey} </Mui.Typography>
          </Mui.Grid>
          </DialogContentText>
        </DialogContent>
        {!loading &&  <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Button
            onClick={() => {
            setDialogOpen(false);
            
            }}
            variant="contained"
            color="default"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
             
            }}
          >
             Back
          </Mui.Button>
          <ReactCopyToClipboard.CopyToClipboard
                            text={generateLicenseStore.created?.licenseKey}
                            onCopy={() => {
                              enqueueSnackbar(
                                t(
                                  `License key has been successfully  copied to clipboard.`
                                ),
                                {
                                  variant: "success",
                                  preventDuplicate: false,
                                }
                              );
                            }}
                          >
          <Mui.Button
            onClick={() => {
            
            
            }}
            variant="contained"
            color="primary"
            style={{
              height: "32px",
              width: "150px",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
             Copy key
          </Mui.Button>
          </ReactCopyToClipboard.CopyToClipboard>
        </DialogActions>}
      </Dialog>


      <Mui.Grid container style={{marginBottom:"0px"}} spacing={3}>
      <Mui.Grid item xs={12} md={12} lg={12}>
          <Mui.Card
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <Mui.CardContent>
              <Formik.Formik
                initialValues={
                  {
                    eT_version:
                      searchParams.get("eT_version") === "null"
                        ? 2
                        : searchParams.get("eT_version"),
                    macId:
                      searchParams.get("macId") === "null"
                        ? ""
                        : searchParams.get("macId"),
                    customerName:
                      searchParams.get("customerName") === "null"
                        ? ""
                        : searchParams.get("customerName"),
                    deviceName:
                      searchParams.get("deviceName") === "null"
                        ? ""
                        : searchParams.get("deviceName"),
                    remarks:
                      searchParams.get("remarks") === "null"
                        ? ""
                        : searchParams.get("remarks"),
                    location: searchParams.get("location") === "null"
                        ? ""
                        : searchParams.get("location"),
                  } as Generate.Form.Values
                }
                validationSchema={Yup.object<Generate.Form.Values>({
                  macId: Yup.string()
                    .nullable()
                    .required(t("Machine ID is required")),
                  customerName:  userStore.user?.RoleName !== "customer" ? Yup.string().nullable()
                  .required(t("Customer name is required")):undefined,
                  deviceName: userStore.user?.RoleName === "customer" ? Yup.string().nullable()
                  .required(t("Device name is required")):undefined,
                })}
                onSubmit={handleCreate}
              >
                {({ isSubmitting, values, submitForm, submitCount }) => (
                  <>
                    <Components.ResponsiveFullscreenDialogWithScroll
                      open={generateLicenseStore.tenantSelectionDialogOpen}
                      disableDividers
                      handleClose={() => {
                        generateLicenseStore.setTenantSelectionDialogOpen({
                          tenantSelectionDialogOpen: false,
                        });

                        if (generateLicenseStore.selectedTenant) {
                          return;
                        }

                        generateLicenseStore.setSelectedArticle({
                          article:
                            generateLicenseStore.cloudArticle as Models.Set.Article,
                        });
                      }}
                      contentText={
                        <Mui.List>
                          {tenantStore.activeTenants.length === 0 && (
                            <MuiLab.Alert severity="error">
                              <MuiLab.AlertTitle>
                                <ReactI18next.Trans i18nKey="No tenants found">
                                  No tenants found
                                </ReactI18next.Trans>
                              </MuiLab.AlertTitle>
                              <ReactI18next.Trans i18nKey="There are no active tenants to choose from">
                                There are no active tenants to choose from.
                                Please add active tenants and try again later!
                              </ReactI18next.Trans>
                            </MuiLab.Alert>
                          )}
                          {tenantStore.activeTenants.map((tenant, index) => (
                            <React.Fragment key={uuidv4()}>
                              <Mui.ListItem
                                button
                                onClick={() => {
                                  generateLicenseStore.setSelectedTenant({
                                    selectedTenant: tenant,
                                  });
                                  generateLicenseStore.setTenantSelectionDialogOpen(
                                    {
                                      tenantSelectionDialogOpen: false,
                                    }
                                  );
                                }}
                              >
                                <Mui.ListItemIcon>
                                  <Mui.Checkbox
                                    edge="start"
                                    checked={
                                      tenant.id ===
                                      generateLicenseStore.selectedTenant?.id
                                    }
                                    tabIndex={-1}
                                  />
                                </Mui.ListItemIcon>
                                <Mui.ListItemText
                                  primary={tenant.companyAliasName}
                                  secondary={tenant.companyName}
                                />
                              </Mui.ListItem>
                              {index !== tenantStore.tenants.length - 1 && (
                                <Mui.Divider />
                              )}
                            </React.Fragment>
                          ))}
                        </Mui.List>
                      }
                      actions={
                        <>
                          <Mui.Button
                            variant="contained"
                            onClick={() => {
                              generateLicenseStore.setSelectedArticle({
                                article:
                                  generateLicenseStore.cloudArticle as Models.Set.Article,
                              });
                              generateLicenseStore.setTenantSelectionDialogOpen(
                                {
                                  tenantSelectionDialogOpen: false,
                                }
                              );
                            }}
                          >
                            Cancel {generateLicenseStore.cloudArticle?.setName}{" "}
                            Article
                          </Mui.Button>
                          <Mui.Button
                            component={Router.Link}
                            color="primary"
                            variant="contained"
                            to="/tenants/create"
                            onClick={() => {
                              const returnUrl = `/licenses/generate?macId=${
                                values.macId
                              }&customerName=${values.customerName}&remarks=${
                                values.remarks
                              }&tenantId=${
                                generateLicenseStore.selectedTenant?.id ?? ""
                              }${generateLicenseStore.onlySelectedArticles.reduce(
                                (acc: string, set) => {
                                  return acc + `&set=${set}`;
                                },
                                ""
                              )}`;

                              tenantStore.setReturnUrl({ returnUrl });
                            }}
                          >
                            Create New Tenant
                          </Mui.Button>
                        </>
                      }
                      title={
                        <>
                          {generateLicenseStore.cloudArticle
                            ? `Select tenant for ${generateLicenseStore.cloudArticle.setName}`
                            : "Select tenant"}
                        </>
                      }
                    />
                    {searchParams.get("macId") === null  ? <> <Mui.Typography style={{opacity:"0.8"}} variant ={"h6"}>New terminal device</Mui.Typography>
                  <Mui.Typography style={{opacity:"0.6" ,marginBottom:"6px"}} variant={"body2"}>Add a new PC/Tablet to create a manual installation key</Mui.Typography></>:<><Mui.Typography style={{opacity:"0.8"}} variant ={"h6"}>Edit terminal device</Mui.Typography>
                  <Mui.Typography style={{opacity:"0.6" ,marginBottom:"6px"}} variant={"body2"}>Edit a existing PC/Tablet to update a manual installation key</Mui.Typography></>}
                  <Generate.Form availableArticles={data} generateFlag={generateFlag}/>
                  <Dialog open={dialogOpenLicenseAgreement} onClose={() => {
          setDialogOpenLicenseAgreement(false);
        }} maxWidth="md" fullWidth>
          <Mui.Box  style ={{margin:"32px"}}>
      <Mui.Typography variant="h6" style={{fontWeight: 700,marginBottom:"12px"}} align="center" >End User License Agreement (EULA)</Mui.Typography>
      <Mui.Typography variant="body1" align="center" style={{marginBottom:"12px"}}>
        for the EasyTouch software from
        </Mui.Typography>
        <Mui.Typography variant="body1" style={{color:"rgb(8,51,98)", fontWeight: 700}} align="center"  paragraph>
        KERN & Sohn GmbH
        </Mui.Typography>
        </Mui.Box>
      <DialogContent dividers style={{ maxHeight: '400px' }}>
        <Mui.Typography variant="body1" paragraph>
          **IMPORTANT: PLEASE READ THIS AGREEMENT CAREFULLY. BY INSTALLING, ACCESSING OR USING THIS SOFTWARE, YOU AGREE TO THE TERMS OF THIS AGREEMENT.**
        </Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
          This End User License Agreement ("EULA") is a legally binding contract between you ("Licensee") and KERN & Sohn GmbH ("Licensor"). By installing, accessing, or using the EasyTouch software ("Software"), you agree to the terms of this EULA.
        </Mui.Typography>
        <Mui.Typography variant="h6">1. Grant of License</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
          The Licensor grants the Licensee a non-exclusive license to use the Software in accordance with the terms of this EULA.
        </Mui.Typography>
        <Mui.Typography variant="h6">2. Rights of Use</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        2.1. The licensee may only install and use the software on as many end devices as the purpose of
the individual EasyTouch app allows. For orientation: The vast majority of apps in the EasyTouch
family are designed and configured so that each license can and may be installed and used on up
to four - 4 - individual devices. By purchasing additional licenses, four additional installations can
be added - typically. The user can independently replace an end device (an installation) with
another installation.
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        <Mui.Typography variant="h6">3. Restrictions</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        3.1. The licensee may not modify, adapt, translate or create derivative works of the software.
3.2. The licensee may not decompile, disassemble or reverse engineer the software unless this is
expressly permitted under applicable law despite this restriction.
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        <Mui.Typography variant="h6"> 4. Property rights</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
4.1. The software is protected by copyright and remains the property of the licensor.
4.2. All right, title and interest in and to the Software, including all copyrights, patents, trademarks
and other intellectual property rights, shall remain with Licensor.
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        <Mui.Typography variant="h6">5. Updates and Upgrades</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        5.1. Licensor may, at its sole discretion, provide updates and upgrades to the Software. Such
updates and upgrades shall be subject to the terms of this EULA, unless other terms apply to
such updates and upgrades.
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        <Mui.Typography variant="h6">6. Disclaimer of Warranties</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        6.1. The Software is provided "as is" and without warranty of any kind. Licensor expressly
disclaims all warranties, express or implied, including the warranties of merchantability, fitness
for a particular purpose and non-infringement of third party rights.
6.2. Licensor does not warrant that the Software will operate error-free or that it will meet all of
Licensee's requirements.
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        <Mui.Typography variant="h6">7. Limitation of Liability</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        7.1. In no event shall Licensor be liable for any direct, indirect, incidental, special or consequential
damages arising out of the use of or inability to use the Software, even if Licensor or Licensee has
been advised of the nossibilitv of such damages. Use of the Software is at I icensee's sole risk.
7.2. In no event shall Licensor's total liability for all damages exceed the amount paid by Licensee
for the Software during the twelve (12) months prior to the occurrence of the damaging event.
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        <Mui.Typography variant="h6">8. Term and Termination</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        8.1. This EULA shall remain in effect until terminated by Licensor or Licensee.

8.2. Licensee may terminate this EULA at any time by deleting all copies of the Software and all
associated materials.

8.3. Licensor may terminate this EULA immediately if Licensee breaches any of the terms of this
EULA. Upon termination of the EULA, Licensee must destroy all copies of the Software and all
aeenriatad matariale
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        <Mui.Typography variant="h6">9. General</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        9.1. This EULA is governed by the laws of the Federal Republic of Germany, excluding the UN
Convention on Contracts for the International Sale of Goods.

9.2. If any provision of this EULA is invalid or unenforceable, the remaining provisions of this EULA
will remain in full force and effect.

9.3. This EULA constitutes the entire agreement between the Licensor and the Licensee with
respect to the use of the Software and supersedes all prior or contemporaneous agreements,
understandings or representations, whether written or oral, with respect to the subject matter of
this EULA.
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        <Mui.Typography variant="h6">10. Contact information</Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        If you have any questions about this EULA or the Software, please contact:
          {/* Add more content here as per the agreement */}
        </Mui.Typography>
        
<Mui.Typography variant="body2" className={classes.contactInfo}>
          KERN & Sohn GmbH<br />
          Ziegelei 1<br />
          72336 Balingen<br />
          Germany<br />
          Telephone: +49 (0)7433 9933-0<br />
          E-mail: <Mui.Link href="mailto:info@kern-sohn.com">info@kern-sohn.com</Mui.Link>
        </Mui.Typography>
          {/* Add more content here as per the agreement */}
          <Mui.Typography variant="body2" paragraph>
        By installing or using the Software, you agree to have read this EULA and to be bound by its
terms.
        </Mui.Typography>
        <Mui.Typography variant="body2" paragraph>
        ** Date of last update: July 15, 2024 **
          {/* Add more content here as per the agreement */}
        </Mui.Typography>

        
        {/* Additional content can go here */}
      </DialogContent>
      <DialogActions>
        <Mui.FormControlLabel
          control={<Mui.Checkbox color="primary" />}
          label="I accept the terms in the license agreement"
        />
        {/* <Mui.Button onClick={onClose} color="primary">
          Cancel
        </Mui.Button> */}
        <Mui.Button color="primary" variant="contained" 
        onClick={async ()=>{
         
          await submitForm()
      
          enqueueSnackbar(
            t(
              `Installation activated successfully`
            ),
            {
              variant: "success",
              preventDuplicate: false,
            }
          );
          navigate('/easy-touch-devices');
          
        }}>
          Continue
        </Mui.Button>
      </DialogActions>
    </Dialog>
                    {generateLicenseStore.selectedTenant && (
                      <>
     
                        <Mui.Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ marginTop: theme.spacing(2) }}
                        >
                          <ReactI18next.Trans i18nKey="Selected tenant">
                            Selected tenant
                          </ReactI18next.Trans>
                        </Mui.Typography>
                        <Mui.Link
                          onClick={() =>
                            generateLicenseStore.setTenantSelectionDialogOpen({
                              tenantSelectionDialogOpen: true,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <Mui.Tooltip
                            title={
                              <ReactI18next.Trans i18nKey="Change selected tenant">
                                Change selected tenant
                              </ReactI18next.Trans>
                            }
                            placement="bottom-start"
                          >
                            <Mui.Typography
                              style={{ marginTop: theme.spacing(0.5) }}
                            >
                              {
                                generateLicenseStore.selectedTenant
                                  ?.companyAliasName
                              }{" "}
                              (
                              {generateLicenseStore.selectedTenant?.companyName}
                              )
                            </Mui.Typography>
                          </Mui.Tooltip>
                        </Mui.Link>
                      </>
                    )}
                   {generateLicenseStore.noOfSelectedArticles > 0 && <Mui.Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: theme.spacing(2) }}
                    >
                      <ReactI18next.Trans i18nKey="Selected license">
                        Selected license(s)
                      </ReactI18next.Trans>
                    </Mui.Typography>}
                    {submitCount > 0 &&
                      generateLicenseStore.noOfSelectedArticles === 0 && (
                        <Mui.FormHelperText error>
                          <ReactI18next.Trans i18nKey="Please select an license before proceeding">
                            Please select an license before proceeding
                          </ReactI18next.Trans>
                        </Mui.FormHelperText>
                      )}
                    {submitCount > 0 &&
                      generateLicenseStore.noOfSelectedArticles > 0 &&
                      !generateLicenseStore.isDependentArticlesValid && (
                        <Mui.FormHelperText error>
                          <ReactI18next.Trans i18nKey="Please select the dependent license">
                            Please select dependent license
                            {` (${Array.from(
                              generateLicenseStore.dependentArticles.values()
                            ).join(", ")})`}
                          </ReactI18next.Trans>
                        </Mui.FormHelperText>
                      )}
                    
                    <Mui.Grid container>
                      {data
                        ?.filter(
                          (article) =>
                            generateLicenseStore.selectedArticles[article.setID]
                        )
                        .map((article) => (
                          <Mui.Tooltip title={article.setName} key={uuidv4()}>
                            <Mui.Avatar
                              style={{
                                marginRight: theme.spacing(2.5),
                                marginTop: theme.spacing(1),
                              }}
                            >
                              <Mui.Box color="text.primary">
                                <i className={article.iconClass} />
                              </Mui.Box>
                            </Mui.Avatar>
                          </Mui.Tooltip>
                        ))}
                    </Mui.Grid>

                    <Mui.Box height={theme.spacing(5)} width={1} />

                    <Mui.Box
                      position="fixed"
                      bottom={theme.spacing(2.5)}
                      right={theme.spacing(2.5)}
                      zIndex={3}
                    >
                       <Mui.Button
                        onClick={()=>{
                          navigate('/easy-touch-devices');
                        }}
                        style={{marginRight:"12px",}}
                        color="default"
                        variant="contained"
                      >
                        <ReactI18next.Trans i18nKey="Back">
                          Back
                        </ReactI18next.Trans>
                      </Mui.Button>
                    
                   {searchParams.get("macId") !== null  &&  <Mui.Button
                           onClick={async () => {
                         
                              await licenseStore.deactivateAll({
                                body: {
                                  MacID: searchParams.get("macId") ?? "0",
                                  UserId: userStore.user?.ETUID ?? 0,
                                },
                              });
                              successAcknowledgement();
                              return;
                            
                          }} 
                        color="default"
                        variant="contained"
                        style={{marginRight:"18px"}}
                      >
                        <ReactI18next.Trans i18nKey="Deactivate terminal device">
                          Deactivate terminal device
                        </ReactI18next.Trans>
                      </Mui.Button> }
                      <Mui.Button
                        onClick={()=>{
                          submitForm()
                          setDialogOpen(true)
                        }}
                        color="default"
                        variant="contained"
                        disabled={gmac}
                        style={{ marginRight: "12px",}}
                      >
                     
                         <ReactI18next.Trans i18nKey="Get Installation key">
                          Get Installation key
                        </ReactI18next.Trans>
                      </Mui.Button>
                  <Mui.Button
                        onClick={async ()=>{
                          setDialogOpenLicenseAgreement(true)
                          //await submitForm()
                      
                          // enqueueSnackbar(
                          //   t(
                          //     `Installation activated successfully`
                          //   ),
                          //   {
                          //     variant: "success",
                          //     preventDuplicate: false,
                          //   }
                          // );
                          // navigate('/easy-touch-devices');
                          
                        }}
                        color="primary"
                        variant="contained"
                        disabled={gmac}
                      >
                     
                         <ReactI18next.Trans i18nKey="Update">
                          Update
                        </ReactI18next.Trans>
                      </Mui.Button>
                    </Mui.Box>
                  </>
                )}
              </Formik.Formik>
            </Mui.CardContent>
          </Mui.Card>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Paper style={{backgroundColor:"#ffffff"}}>
        <Mui.Grid container spacing={1}>
        <Mui.Grid item xs={12} md={7} lg={7}>
        <Mui.Typography style={{opacity:"0.8",padding:"16px"}} variant ={"h6"}>Select the license(s) for installation(s)</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item xs={12} md={5} lg={5}>
          <Mui.Grid container alignItems="center">
            <Mui.Grid item xs={6} >
              <Mui.FormControl
                style={{ width: 200, marginBottom: theme.spacing(4.5) }}
              >
                <Mui.TextField
                  label={t("Search")}
                  value={search}
                  onChange={handleSearch}
                />
              </Mui.FormControl>
            </Mui.Grid>

            <Mui.Grid item xs={6}>
             <Mui.FormControl style={{ marginBottom: theme.spacing(1.5) }}>
                <Mui.FormControlLabel
                  label={t("Select all")}
                  control={
                    <Mui.Checkbox
                    style={{color : "#4d79ff"}}
                      indeterminate={
                        data
                          ?.filter((article) => {
                            if (!search) {
                              return true;
                            }
                            return (
                              (article.setName &&
                                article.setName
                                  .toLowerCase()
                                  .includes(search.toLowerCase()))
                                   ||
                              (article.setCode &&
                                article.setCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.hundCode &&
                                article.hundCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase()))
                            );
                          })
                          .filter(
                            (article) =>
                              generateLicenseStore.selectedArticles[
                                article.setID
                              ]
                          ).length > 0 &&
                        data
                          ?.filter((article) => {
                            if (!search) {
                              return true;
                            }
                            return (
                              (article.setName &&
                                article.setName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.setCode &&
                                article.setCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.hundCode &&
                                article.hundCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase()))
                            );
                          })
                          .filter(
                            (article) =>
                              generateLicenseStore.selectedArticles[
                                article.setID
                              ]
                          ).length <
                          data?.filter((article) => {
                            if (!search) {
                              return true;
                            }
                            return (
                              (article.setName &&
                                article.setName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.setCode &&
                                article.setCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.hundCode &&
                                article.hundCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase()))
                            );
                          }).length
                      }
                      checked={
                       data?.filter((article) => {
                          if (!search) {
                            return true;
                          }
                          return (
                            (article.setName &&
                              article.setName
                                .toLowerCase()
                                .includes(search.toLowerCase())) ||
                            (article.setCode &&
                              article.setCode
                                .toLowerCase()
                                .includes(search.toLowerCase())) ||
                            (article.hundCode &&
                              article.hundCode
                                .toLowerCase()
                                .includes(search.toLowerCase()))
                          );
                        }).length > 0 &&
                        data
                          ?.filter((article) => {
                            if (!search) {
                              return true;
                            }
                            return (
                              (article.setName &&
                                article.setName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.setCode &&
                                article.setCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.hundCode &&
                                article.hundCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase()))
                            );
                          })
                          .filter(
                            (article) =>
                              generateLicenseStore.selectedArticles[
                                article.setID
                              ]
                          ).length ===
                          data?.filter((article) => {
                            if (!search) {
                              return true;
                            }
                            return (
                              (article.setName &&
                                article.setName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.setCode &&
                                article.setCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (article.hundCode &&
                                article.hundCode
                                  .toLowerCase()
                                  .includes(search.toLowerCase()))
                            );
                          }).length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": t("select all articles"),
                      }}
                    />
                  }
                />
              </Mui.FormControl>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container spacing={3}>
        
        <Mui.Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ height: "calc(100vh - 274px)", overflow: "auto" , paddingLeft:"20px" , paddingRight:"20px"}}
        >
          <Article.Views.Cards
            selectedArticles={generateLicenseStore.selectedArticles}
            disablePagination
            cardsPerRow={4}
            spacing={2}
            variant="DETAILED"
            handleClick={({ article }) => {
              console.log("article"+JSON.stringify(article))
              
              generateLicenseStore.setSelectedArticle({ article });
              
           
            }}
            functions={functionStore.functions}
            articles={data?.filter((article) => {
              if (!search) {
                return true;
              }
              return (
                (article.setName &&
                  article.setName
                    .toLowerCase()
                    .includes(search.toLowerCase())) ||
                (article.setCode &&
                  article.setCode
                    .toLowerCase()
                    .includes(search.toLowerCase())) ||
                (article.hundCode &&
                  article.hundCode.toLowerCase().includes(search.toLowerCase()))
              );
            })}
          />
        </Mui.Grid>
      </Mui.Grid>
      </Mui.Paper>
    </>
  );
});
