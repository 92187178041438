/** @format */

import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as DateFns from "date-fns";
import * as Mobx from "mobx-react-lite";
import Numeral from "numeral";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import RefreshIcon from '@material-ui/icons/Refresh';
import * as Components from "src/app/components";
import * as Containers from "src/app/containers";
import * as Order from "src/app/pages/order";
import * as Models from "src/models";
import * as Services from "src/services";
import * as App from "src/app";
import * as Constants from "src/constants";
import * as Router from "react-router-dom";
import * as MuiIcon from "@material-ui/icons";
import ExcelComponent from "src/app/components/export_excel";

interface Data extends Models.Order.Main {}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(
  props: EnhancedTableProps & {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    leftmost?: boolean;
    rightmost?: boolean;
  }
) {
  const { classes, order, orderBy, onRequestSort, ...restOfProps } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = Mui.useTheme();

  return (
    <Components.Mui.TableCell
      {...restOfProps}
      color={theme.palette.divider}
      align={props.numeric ? "right" : "left"}
      padding={props.disablePadding ? "none" : "default"}
      sortDirection={orderBy === props.id ? order : false}
    >
      <Mui.TableSortLabel
        active={orderBy === props.id}
        direction={orderBy === props.id ? order : "asc"}
        onClick={createSortHandler(props.id as keyof Data)}
      >
        {props.label}
        {orderBy === props.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </Mui.TableSortLabel>
    </Components.Mui.TableCell>
  );
}

const useStyles = Mui.makeStyles((theme) =>
  Mui.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    title: {
      flexGrow: 1,
    },
  })
);

function EnhancedTable({ rows }: { rows: Models.Order.Main[] }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderedOn, setOrderedOn] = React.useState<any>();
  const [orderBy, setOrderBy] = React.useState<keyof Data>("orderCode");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(7);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderId, setOrderId] = React.useState<number | string>();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] =
    React.useState<boolean>(false);
  const userStore = React.useContext(App.Contexts.userStore);
  const userRole = userStore.user?.RoleName;
  const uid = userStore.user?.ETUID;
  React.useEffect(() => {
    setPage(0);
  }, [rows]);
  const key = "orderCode";

  const orderArrayUniqueByKey = [
    ...new Map(rows.map((item) => [item[key], item])).values(),
  ];
  // const orderDetaile = orderArrayUniqueByKey.map(k=>k.orderDetails[0])
  const orderDetaile = rows;
  // const orderedDetailed = orderDetaile.filter(k=>k.orderCode === "ET-20220219-00006")
  // const setFilter = orderedDetailed.map(k=>k.sets)
  //   
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //  const license = orderArrayUniqueByKey.map(k=>k.orderDetails).filter((k:any)=>{k.orderCode === OrderCodeForLicense }).map(k=>k.sets)
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.orderCode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };


  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { t } = ReactI18next.useTranslation();
  const theme = Mui.useTheme();
  const [language, setLanguage] = React.useState("en-IN");
  const { i18n } = ReactI18next.useTranslation();
  const languageValue = i18n.language;
  const orderServices = new Services.Order({
    httpManager: new Services.Managers.Axios({ agent: App.axios }),
    urlFactory: new Services.Factories.URL({
      protocol: Constants.environment.apiBaseUrlProtocol as "http",
      hostname: Constants.environment.apiBaseUrlHostname,
      version: Constants.environment.apiBaseUrlVersion,
    }),
  });
  React.useEffect(() => {
    if (languageValue === "en") {
      setLanguage("en-IN");
    } else if (languageValue === "fr") {
      setLanguage("fr-FR");
    } else {
      setLanguage("de-DE");
    }
  }, [languageValue]);
  const [orderCode, setOrderCode] = React.useState<any>();
  const [OrderCodeForLicense, setOrderCodeForLicense] = React.useState<any>();
  const [orders, setOrders] = React.useState<Models.Order.Main>();
  const navigate = Router.useNavigate();
  const orderStore = React.useContext(App.Contexts.orderStore);

  React.useEffect(() => {
    (async function () {
      if (!orderId) {
        return;
      }

      try {
        const {
          result: [order],
        } = await orderServices.getData({
          body: { id: orderId, cid: uid },
        });

        var { result } = await orderServices.getData({
          body: { id: orderId, cid: uid },
        });

        //overAllStatus = 3 -- > Order completely returned
        if (result.find((k: any) => k.status === 3)) {
          order.overAllStatus = 3;
          var partialReturnOrderObjectForStatus3 = result.find(
            (k: any) => k.status === 2
          );
          partialReturnOrderObjectForStatus3?.orderDetails.map((k: any) => {
            // eslint-disable-next-line array-callback-return
            order.orderDetails.map((q: any) => {
              if (q.setID === k.setID) {
                q.returnQuantity = k.quantity;
              }
            });
          });
        }
        //overAllStatus = 2 -- > Order paretially returned
        else if (result.find((k: any) => k.status === 4)) {
          order.overAllStatus = 4;
          var partialReturnOrderObjectForStatus4 = result.find(
            (k: any) => k.status === 2
          );
          partialReturnOrderObjectForStatus4?.orderDetails.map((k: any) => {
            // eslint-disable-next-line array-callback-return
            order.orderDetails.map((q: any) => {
              if (q.setID === k.setID) {
                q.returnQuantity = k.quantity;
              }
            });
          });
          // 
        } else if (result.find((k: any) => k.status === 5)) {
          order.overAllStatus = 5;
          var partialReturnOrderObjectForStatus5 = result.find(
            (k: any) => k.status === 2
          );
          partialReturnOrderObjectForStatus5?.orderDetails.map((k: any) => {
            // eslint-disable-next-line array-callback-return
            order.orderDetails.map((q: any) => {
              if (q.setID === k.setID) {
                q.returnQuantity = k.quantity;
              }
            });
          });
          // 
        }
        //overAllStatus = 1 -- > Order paretially returned Order with no return
        else {
          order.overAllStatus = 1;
        }
        
        setOrders(order);
      } catch (error) {
        console.error(error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailsDialogOpen]);
  // 
  return (
    <div className={classes.root}>
      <Components.LightBox
        open={orderDetailsDialogOpen}
        handleClose={() => setOrderDetailsDialogOpen(false)}
        content={
          <Order.Details
            orderId={orderId}
            orderCode={orderCode}
            orderedOn={orderedOn}
          />
        }
        title={
          <>
            <Mui.Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <Mui.Grid item xs={2} style={{ display: "flex" }}>
                <Mui.Typography
                  style={{ fontWeight: "bold" }}
                  variant="h6"
                  className={classes.title}
                >
                  <ReactI18next.Trans i18nKey="Order details">
                    Order details
                  </ReactI18next.Trans>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={2} style={{ display: "flex" }}>
                <Components.OrderStatus
                  status={orders?.overAllStatus as 1 | 2 | 3 | 4 | 5}

                />
              </Mui.Grid>
            </Mui.Grid>
          </>
        }
        actions={
          <>
            <Mui.Button
              variant="contained"
              className="noprint"
              onClick={() => setOrderDetailsDialogOpen(false)}
            >
              <ReactI18next.Trans i18nKey="CLOSE">CLOSE</ReactI18next.Trans>
            </Mui.Button>
            <Mui.Button
              className="noprint"
              variant="contained"
              color="primary"
              onClick={() => {
                window.print();
              }}
            >
              <ReactI18next.Trans i18nKey="Print">Print</ReactI18next.Trans>
            </Mui.Button>
          </>
        }
      />
      <Mui.TableContainer>
        <Mui.Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {rows.length > 0 && (
            <Mui.TableHead>
              <Mui.TableRow>
                <EnhancedTableHead
                  leftmost
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="orderedOn"
                  label={t("Order date")}
                />
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="orderCode"
                  label={t("Order #")}
                />

                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="orderedBy"
                  label={t("Ordered by")}
                />
                <Containers.ShowIfAuthorised roles={["superadmin", "admin"]}>
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    id="companyName"
                    label={t("Company")}
                  />
                </Containers.ShowIfAuthorised>

               

                {/* <EnhancedTableHead
               classes={classes}
               numSelected={selected.length}
               order={order}
               orderBy={orderBy}
               onSelectAllClick={handleSelectAllClick}
               onRequestSort={handleRequestSort}
               rowCount={rows.length}
               id="orderedOn"
               label={t("Date")}
             /> */}
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  id="grossTotalPriceInclVAT"
                  label={t("Status")}
                  rightmost
                />
              
              </Mui.TableRow>
            </Mui.TableHead>
          )}
          <Mui.TableHead>
            <tr></tr>
          </Mui.TableHead>
          <Mui.TableBody>
            {stableSort(
              orderArrayUniqueByKey as any,
              getComparator(order, orderBy)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.orderCode as string);
                const labelId = `enhanced-table-checkbox-${index}`;
                const grossTotalPriceInclVAT: any = row.grossTotalPriceInclVAT;
                const totalPrice =
                  row?.vatTaxPercent === 0
                    ? (19 / 100) * grossTotalPriceInclVAT +
                      +grossTotalPriceInclVAT
                    : row.grossTotalPriceInclVAT;
               
                var keys = ["setName"];
                return (
                  <Mui.TableRow
                    hover
                    onClick={(event) => {
                      handleClick(event, row.orderCode as string);
                      // orderStore.clearOrder();
                      //    navigate(
                      //    `manage-order?q=${row.orderID}&m=${row.status}&cid=${row.etuid}`
                      //   );
                      if (userStore.user?.RoleName !== "customer") {
                        orderStore.clearOrder();
                        navigate(
                          `manage-order?q=${row.orderID}&m=${row.status}&cid=${row.etuid}`
                        );
                      } else {
                        setOrderCode(row.orderCode);
                        setOrderId(row.orderID);
                        setOrderDetailsDialogOpen(true);
                        setOrderedOn(row.orderedOn);
                      }
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderCode}
                  >
                    <Components.Mui.TableCell leftmost>
                      {DateFns.format(new Date(row.orderedOn), "dd.MM.yyyy")}
                    </Components.Mui.TableCell>
                    <Components.Mui.TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.dataFrom === "webshop" &&
                      row.sageOLReferenceBillID != null &&
                      row.assignedTo === "0"
                        ? row.orderCode + "-" + row.sageOLReferenceBillID
                        : row.orderCode}
                    
                    </Components.Mui.TableCell>

                    <Containers.ShowIfAuthorised roles={["customer"]}>
                      <Components.Mui.TableCell align="left">
                        {row.dataFrom === "webshop" && row.assignedTo === "0"
                          ? "-"
                          : row.assignedTo === "0"
                          ? "KERN"
                          : row.userFirstName + " " + row.userLastName}
                      </Components.Mui.TableCell>
                    </Containers.ShowIfAuthorised>
                    
                    <Containers.ShowIfAuthorised roles={["dealer"]}>
                      <Components.Mui.TableCell>
                        {row.dataFrom === "webshop" && row.assignedTo === "0"
                          ? "-"
                          : userStore.user?.FirstName +
                            " " +
                            userStore.user?.LastName}
                      </Components.Mui.TableCell>
                    </Containers.ShowIfAuthorised>

                    <Containers.ShowIfAuthorised
                      roles={["superadmin", "admin"]}
                    >
                      <Components.Mui.TableCell>
                        {row.dataFrom === "webshop" && row.assignedTo === "0"
                          ? "-"
                          : row.userFirstName + " " + row.userLastName}
                      </Components.Mui.TableCell>
                    </Containers.ShowIfAuthorised>
                    <Containers.ShowIfAuthorised
                      roles={["superadmin", "admin"]}
                    >
                      <Components.Mui.TableCell>
                        {row.companyName}
                      </Components.Mui.TableCell>
                    </Containers.ShowIfAuthorised>
                    <Components.Mui.TableCell rightmost>
                      <Mui.Grid container justify="space-between">
                        <Components.OrderStatus
                          status={row?.status as 1 | 2 | 3 | 4 | 5}

                        />
                        <Mui.IconButton
                          size="medium"
                          style={{
                            padding: theme.spacing(0.125),
                            fontSize: "18px",
                          }}
                        >
                          <MuiIcon.ChevronRight
                            style={{ fontSize: "30px" }}
                            color="primary"
                          />
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Components.Mui.TableCell>

                   
                  </Mui.TableRow>
                );
              })}
            {rows.length === 0 && (
              <Mui.TableCell
                colSpan={12}
                style={{ border: "none", padding: 0 }}
              >
                <Mui.Grid container>
                  <Mui.Grid item xs={12}>
                    <MuiLab.Alert severity="info">
                      <ReactI18next.Trans i18nKey="No orders found">
                        No orders found
                      </ReactI18next.Trans>
                    </MuiLab.Alert>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.TableCell>
            )}
            {emptyRows > 0 && (
              <Mui.TableRow style={{ display: "none", height: 53 * emptyRows }}>
                <Mui.TableCell colSpan={6} />
              </Mui.TableRow>
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {rows.length > 7 && (
        <Mui.Grid container justify="flex-end" alignItems="flex-end">
          <MuiLab.Pagination
            style={{ marginTop: theme.spacing(2) }}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            page={page + 1}
            count={Math.ceil(rows.length / rowsPerPage)}
            // NOTE: Mui Lab Pagination assumes page starts at 1
            onChange={(event, page) => handleChangePage(event, page - 1)}
            shape="rounded"
          />
        </Mui.Grid>
      )}
    
    </div>
  );
}

export const Main = Mobx.observer(function () {
  const [search, setSearch] = React.useState<string>("");
  const userStore = React.useContext(App.Contexts.userStore);
  const orderStore = React.useContext(App.Contexts.orderStore);
  const theme = Mui.useTheme();

  React.useEffect(() => {
    const uid = userStore.user?.ETUID;
    const userRole = userStore.user?.RoleName ?? "customer";
    if (
      userRole === "customer" ||
      userRole === "dealer" ||
      userRole === "admin" ||
      userRole === "superadmin"
    ) {
      if (uid !== undefined && uid !== null) {
        orderStore.read({
          uid,
          userRole,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string);
  };
  const handleClick = async () => {
    try {
      const uid = userStore.user?.ETUID;
      const userRole = userStore.user?.RoleName ?? "customer";
      if (
        userRole === "customer" ||
        userRole === "dealer" ||
        userRole === "admin" ||
        userRole === "superadmin"
      ) {
        if (uid !== undefined && uid !== null) {
          orderStore.read({
            uid,
            userRole,
          });
        }
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
  };
  const { t } = ReactI18next.useTranslation();
  return (
    <>
      <Mui.Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Mui.FormControl style={{ width: 300 }}>
          {orderStore.orders.length > 0 && (
            <Mui.TextField label={t("Search")} onChange={handleSearch} />
          )}
        </Mui.FormControl>
       
        <ExcelComponent fileName="Order_List.xlsx" headerObject={['OrderID','customer name']} contentList={orderStore.orders}/>
        <Mui.Button style={{color:"grey",fontSize:"14px"}} onClick={handleClick}><RefreshIcon 
                      
                      /><Mui.Typography style={{color:"grey",fontSize:"14px"}}>Refresh</Mui.Typography></Mui.Button>
      </Mui.Grid>
      {orderStore.isInitialLoading ? (
        <Mui.Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          minHeight={"100%"}
          maxHeight={"100%"}
          height={400}
          flexDirection="column"
        >
          <Mui.CircularProgress />
        </Mui.Box>
      ) : (
        <>
          {orderStore.view === "CARD" && (
            <Order.Views.Cards
              orders={orderStore.orders.filter((order) => {
                if (!search) {
                  return true;
                }
                return (
                  order?.orderCode
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.userFirstName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.userLastName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.companyName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  (order.orderedOn &&
                    DateFns.format(new Date(order.orderedOn), "dd.MM.yyyy")
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                );
              })}
            />
          )}
          {orderStore.view === "TABULAR" && (
            <EnhancedTable
              rows={orderStore.orders.filter((order) => {
                if (!search) {
                  return true;
                }
                return (
                 (order?.orderCode && (order?.orderCode + "-" + order?.sageOLReferenceBillID)
                    ?.toLowerCase()
                    .includes(search.toLowerCase())) ||
                  order?.userFirstName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.userLastName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  order?.companyName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  (order.orderedOn &&
                    DateFns.format(new Date(order.orderedOn), "dd.MM.yyyy")
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                );
              })}
            />
          )}
         
        </>
      )}
    </>
  );
});
